import React from "react";

function Market() {
  return (
    <div id="Market">
      <h1 className="pt-3">el market del market</h1>
      <div className="container-fluid">
        <div className="row">
          {[
            {
              path: "https://res.cloudinary.com/dazn99jie/image/upload/v1726627318/bolt/kjd7i3jrxfr7hvozflam.png",
              name: "FFFR Blanca",
              price: "15.000 BPs",
            },
            {
              path: "https://res.cloudinary.com/dazn99jie/image/upload/v1726627339/bolt/xpesioecp8k0kqhhmayi.png",
              name: "FFFR Negra",
              price: "15.000 BPs",
            },
            {
              path: "https://res.cloudinary.com/dazn99jie/image/upload/v1726627366/bolt/mo9wqftommxuzkbijbp5.png",
              name: "FY & FFF Negra",
              price: "15.000 BPs",
            },
            {
              path: "https://res.cloudinary.com/dazn99jie/image/upload/v1726627387/bolt/lhqdxls5imkixbgv7mlu.png",
              name: "FFF",
              price: "15.000 BPs",
            },
          ].map((product) => (
            <div className="col-6 col-lg-3" key={product.name}>
              <article>
                <figure style={{ overflow: "hidden" }} className="mb-1">
                  <img src={product.path} />
                </figure>
                <div>
                  <div style={{ fontSize: "10px", textAlign: "center" }}>
                    {product.name} - <b>AGOTADO</b>
                  </div>
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    ${product.price}
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Market;
