import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadingSlice";

function Orders() {
  const [orders, setOrders] = useState();
  const [approved, setApproved] = useState(false);
  const [openOrder, setOpenOrder] = useState();
  const [selled, setSelled] = useState();
  const [withdrawn, setWithdrawn] = useState();
  //Estados
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Constantes
  useEffect(() => {
    const fetchOrders = async () => {
      dispatch(setLoading(true));
      try {
        const response = await axios.get(
          `https://boltrev-api.vercel.app/admin/orders`,
          {
            headers: {
              Authorization: `${Cookies.get("token")}`,
            },
          }
        );
        if (!approved) {
          setOrders(response.data.orders);
        } else {
          setOrders(
            response.data.orders.filter((o) => o.status.includes("approved"))
          );
        }
        setSelled(response.data.selled);
        setWithdrawn(response.data.withdrawn);
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        navigate("/login");
      }
    };
    fetchOrders();
  }, [navigate, dispatch, approved]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-UY", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };
  //Funciones

  return (
    <div id="Orders">
      {orders && (
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <div className="register-box">
                <h1 className="mb-5">Todas las ordenes</h1>
                <h4>Vendido: ${selled}</h4>
                <h4>Retirado: ${withdrawn}</h4>
                <h4>Ganancias: ${Math.round(selled * 0.07)}</h4>
                <h4>Deuda Externa: ${Math.round(selled * 0.85 - withdrawn)}</h4>
                <hr />
                <button
                  className="d-flex w-100 justify-content-center mb-4"
                  onClick={() => setApproved(!approved)}
                >
                  {approved ? "Ver todas" : "Ver pagadas"}
                </button>
                {orders.map((order) => (
                  <div
                    onClick={() => {
                      if (openOrder !== order.id) {
                        setOpenOrder(order.id);
                      } else {
                        setOpenOrder(null);
                      }
                    }}
                    style={
                      order.status.includes("approved")
                        ? { backgroundColor: "green" }
                        : {}
                    }
                    className={
                      openOrder === order.id
                        ? "orderData openData"
                        : "orderData"
                    }
                    key={order.id}
                  >
                    <h2>#{order.id}</h2>
                    <h4>{order.name}</h4>
                    <p>Email: {order.email}</p>
                    <p>Dirección: {order.address}</p>
                    <p>Celular: {order.phone}</p>
                    <p>Fecha: {formatDate(order.createdAt)}</p>
                    <p>Estado: {order.status}</p>
                    <p>Total orden: ${order.total}</p>
                    <hr />
                    {order.orderProducts &&
                      order.orderProducts.map((op) => (
                        <div key={op.id}>
                          <h5>{op.product.store.title}</h5>
                          <h6>{op.product.title}</h6>
                          <p>Notas de producto: {op.productNote}</p>
                          <p>Precio: ${op.product.price}</p>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Orders;
