import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";
import { Link } from "react-router-dom";

function Exlusive() {
  const [products, setProducts] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const productsResponse = await axios.get(
          "https://boltrev-api.vercel.app/products"
        );
        setProducts(productsResponse.data.products);
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    };

    fetchData();
  }, [dispatch]);
  return (
    <div id="Exlusive">
      <div className="container-fluid">
        <div className="row">
          {products &&
            products.map((product, index) => (
              <Link
                to={`/product/${product.id}`}
                className="col-6 col-lg-3 mb-5"
                key={product.id}
              >
                <article>
                  <figure style={{ overflow: "hidden" }} className="mb-1">
                    <img
                      className="listImg"
                      alt={`Imagen de product ${product.title}`}
                      src={product.images[0] ? product.images[0].path : ""}
                    />
                  </figure>
                  <div>
                    <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {product.title}
                    </div>
                    <div style={{ fontSize: "10px", fontWeight: "bold" }}>
                      {product.sale && <del>${product.sale}</del>}{" "}
                      <span>${product.price}</span>
                    </div>
                  </div>
                </article>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Exlusive;
