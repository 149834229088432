import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadingSlice";
import { useNavigate } from "react-router-dom";

const CrearProducto = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    visibility: "",
    sizes: "",
    categoryId: "",
    storeId: "", // Campo de storeId agregado
    // Agrega aquí más campos según los datos del producto
  });
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    try {
      await axios.post(
        "https://boltrev-api.vercel.app/admin/products",
        formData,
        {
          headers: {
            Authorization: `${Cookies.get("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(setLoading(false));
      navigate("/gestion-productos");
    } catch (error) {
      dispatch(setLoading(false));
      alert("Error al crear el producto");
    }
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Crear Producto</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Título
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Descripción
          </label>
          <input
            type="text"
            className="form-control"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="price" className="form-label">
            Precio
          </label>
          <input
            type="number"
            className="form-control"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="visibility" className="form-label">
            Visibilidad
          </label>
          <input
            type="number"
            className="form-control"
            id="visibility"
            name="visibility"
            value={formData.visibility}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="sizes" className="form-label">
            Talles
          </label>
          <input
            type="text"
            className="form-control"
            id="sizes"
            name="sizes"
            value={formData.sizes}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="categoryId" className="form-label">
            CategoryId
          </label>
          <input
            type="int"
            className="form-control"
            id="categoryId"
            name="categoryId"
            value={formData.categoryId}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="storeId" className="form-label">
            StoreId
          </label>
          <input
            type="int"
            className="form-control"
            id="storeId"
            name="storeId"
            value={formData.storeId}
            onChange={handleInputChange}
            required
          />
        </div>
        {/* Agrega aquí más campos según los datos del producto */}
        <button type="submit" className="btn btn-primary">
          Crear Producto
        </button>
      </form>
    </div>
  );
};

export default CrearProducto;
