import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { setLoading } from "../redux/loadingSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function CreateOrder() {
  const reduxCart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState();
  const [valid, setValid] = useState(false);
  const [retiro, setRetiro] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchUserInfo = async () => {
      if (Cookies.get("token")) {
        dispatch(setLoading(true));
        try {
          const response = await axios.get(
            "https://boltrev-api.vercel.app/authentication/user-info",
            {
              headers: {
                Authorization: `${Cookies.get("token")}`,
              },
            }
          );
          if (response.data.valid) {
            setName(response.data.name);
            setEmail(response.data.email);
            setPhone(response.data.phone);
            setAddress(response.data.address);
          }
          setValid(response.data.valid);
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setLoading(false));
        }
      }
    };
    fetchUserInfo();
  }, [dispatch]);

  const fecha = new Date(Date.now());

  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1;
  const año = fecha.getFullYear();
  const horas = fecha.getHours();
  const minutos = fecha.getMinutes();
  const segundos = fecha.getSeconds();

  const fechaFormateada = `${dia}/${mes}/${año} ${horas}:${minutos}:${segundos}`;

  const navigate = useNavigate();

  const handleCreateOrder = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    const products = [];
    reduxCart.items.forEach((item) => {
      products.push({ productId: item.id, productNote: item.productNote });
    });
    try {
      const response = await axios.post(
        "https://boltrev-api.vercel.app/create-order",
        {
          phone,
          address,
          name,
          email,
          products,
        }
      );
      navigate(`/order/${response.data.orderId}`);
      dispatch(setLoading(false));
    } catch (error) {
      setError(error.response.data.message);
      dispatch(setLoading(false));
    }
  };

  return (
    <div id="CreateOrder">
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 col-md-6">
            {error && (
              <div className="alert alert-warning" role="alert">
                {error}
              </div>
            )}
            {!valid && (
              <div className="alert alert-warning" role="alert">
                Para acumular boltpoints{" "}
                <a
                  style={{ color: "blue", textDecoration: "underline" }}
                  href="/profile"
                >
                  inicie sesión
                </a>{" "}
                y valide su email
              </div>
            )}
{/*             <div className="shipOption mb-3">
              <button
                type="button"
                onClick={() => {
                  setRetiro(true);
                  setAddress("PICKUP");
                }}
                className={retiro ? "active" : ""}
              >
                Pick Up (Pocitos)
              </button>
              <button
                type="button"
                onClick={() => {
                  setRetiro(false);
                  setAddress("");
                }}
                className={!retiro ? "active" : ""}
              >
                Envío + $200
              </button>
            </div> */}
            <form onSubmit={(e) => handleCreateOrder(e)}>
              <input
                className="w-100 mb-4"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
                required
              />
              <input
                className="w-100 mb-4"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                className="w-100 mb-4"
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Celular"
                required
              />
              {!retiro && (
                <>
                  <input
                    className="w-100 mb-4"
                    type="text"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Dirección completa"
                    required
                  />
                </>
              )}
              <hr className="mt-1 mb-4" />
              <button className="w-100 mb-4" type="submit">
                CONTINUAR
              </button>
              <button
                onClick={() => {
                  window.open(
                    `https://wa.me/+59891265817?text=Necesito ayuda para crear mi orden en BoltMarket`,
                    "_blank"
                  );
                }}
                className="wsp mt-3 mb-5 w-100"
              >
                ¿ NECESITAS AYUDA ? <i className="fa-brands fa-whatsapp"></i>
              </button>
            </form>
          </div>
          <div className="col-12 col-md-6">
            <div>
              <div className="text-center mt-3">
                <div>
                  <section className="receipt container-ticket">
                    <div className="ticket">
                      <div className="head-ticket">
                        <p className="x-bold">BoltMarket® </p>
                        <p className="bold">Metaverso</p>
                        <p className="bold">Tel: +598 91 265 817</p>
                        <p>Date: {fechaFormateada}</p>
                      </div>
                      <div className="body-ticket">
                        <div className="produits">
                          {reduxCart.items.map((item) => (
                            <div key={item.cartId} className="col2">
                              <p style={{ textAlign: "left" }}>
                                {item.title}
                                <br />
                                <small>{item.productNote}</small>
                              </p>
                              <p className="prix">
                                <b>${item.price}</b>
                              </p>
                            </div>
                          ))}
                          <div className="hr-sm"></div>
                          <div className="col2">
                            <p>Subtotal</p>
                            <p className="prix">
                              <b>
                                $
                                {reduxCart.items.reduce(
                                  (total, item) => total + item.price,
                                  0
                                )}
                              </b>
                            </p>
                          </div>
                          <div className="col2">
                            <p>Envío</p>
                            <p className="prix">
                              {address == "PICKUP" ? (
                                <b>Retiro en PickUp</b>
                              ) : (
                                <b>+ $200</b>
                              )}
                            </p>
                          </div>
                          <div className="col2">
                            <p>Método de pago</p>
                            <p className="prix">
                              <b>M.P.</b>
                            </p>
                          </div>
                          <div className="col2">
                            <p>Estado de pago</p>
                            <p className="prix">
                              <b>No paga</b>
                            </p>
                          </div>
                        </div>
                        <div className="hr-lg"></div>
                        <div className="carte">
                          <p className="title-carte">Nombre: {name}</p>
                          <p className="title-carte">Email: {email}</p>
                          <p className="title-carte">Celular: {phone}</p>
                          <p className="title-carte">Dirección: {address}</p>
                        </div>
                        <div className="hr-lg"></div>
                      </div>
                      <div className="footer-ticket">
                        <p className="title-footer">THANK YOU</p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateOrder;
