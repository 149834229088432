import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadingSlice";
import { useNavigate } from "react-router-dom";

const GestionProducts = () => {
  const [products, setProducts] = useState([]);
  const [image, setImage] = useState(null);

  const handleImageUpload = (event) => {
    setImage(event.target.files[0]);
  };

  const uploadImage = async (productId) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("file", image); // Cambiamos "image" por "file" como espera Cloudinary
    formData.append("upload_preset", "ka6y5bia"); // El preset unsigned
    formData.append("folder", "bolt"); // La carpeta en Cloudinary

    const response = await fetch(
      "https://api.cloudinary.com/v1_1/dazn99jie/image/upload",
      {
        method: "POST",
        body: formData,
      }
    );

    const data = await response.json();
    console.log(data.secure_url); // La URL segura de la imagen subida

    const newImage = {
      path: data.secure_url,
      productId: productId,
    };

    handleAddImage(newImage); // Función para manejar la imagen subida
    document.getElementById("fileInput").value = ""; // Limpiar el input
    setImage(null); // Reiniciar la imagen en el estado
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Función para cargar todos los productos con imágenes
  const loadProducts = async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        "https://boltrev-api.vercel.app/admin/products",
        {
          headers: {
            Authorization: `${Cookies.get("token")}`,
          },
        }
      );
      setProducts(response.data);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      navigate("/login");
    }
  };

  // Cargar productos al montar el componente
  useEffect(() => {
    loadProducts();
  }, []);

  // Función para manejar la actualización de un producto por su ID
  const handleUpdateProduct = async (productId, updatedData) => {
    dispatch(setLoading(true));
    try {
      await axios.put(
        `https://boltrev-api.vercel.app/admin/products/${productId}`,
        updatedData,
        {
          headers: {
            Authorization: `${Cookies.get("token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      loadProducts();
    } catch (error) {
      dispatch(setLoading(false));
      alert("Error");
    }
  };

  // Función para manejar la eliminación de un producto por su ID
  const handleDeleteProduct = async (productId) => {
    dispatch(setLoading(true));
    try {
      await axios.delete(
        `https://boltrev-api.vercel.app/admin/products/${productId}`,
        {
          headers: {
            Authorization: `${Cookies.get("token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      loadProducts();
    } catch (error) {
      dispatch(setLoading(false));
      alert("Error");
    }
  };

  const handleAddImage = async (newImage) => {
    try {
      const response = await axios.post(
        `https://boltrev-api.vercel.app/admin/images`,
        newImage,
        {
          headers: {
            Authorization: `${Cookies.get("token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      loadProducts();
    } catch (error) {
      dispatch(setLoading(false));
      alert("Error");
    }
  };

  const handleUpdateImage = async (imageId) => {
    dispatch(setLoading(true));
    try {
      await axios.put(
        `https://boltrev-api.vercel.app/admin/images/${imageId}`,
        {},
        {
          headers: {
            Authorization: `${Cookies.get("token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      loadProducts();
    } catch (error) {
      dispatch(setLoading(false));
      alert("Error");
    }
  };

  const handleDeleteImage = async (productId, imageId) => {
    const confirmDelete = window.confirm(
      "¿Seguro que quieres borrar la imagen?"
    );

    if (!confirmDelete) {
      return;
    }
    dispatch(setLoading(true));
    try {
      await axios.delete(
        `https://boltrev-api.vercel.app/admin/images/${imageId}`,
        {
          headers: {
            Authorization: `${Cookies.get("token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      loadProducts();
    } catch (error) {
      dispatch(setLoading(false));
      alert("Error");
    }
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Gestión de Productos</h1>
      <a href="/creacion-producto">CREAR</a>
      {products.map((product) => (
        <div key={product.id} className="card mb-4">
          <div className="card-body">
            {/* Formulario para editar el producto */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const updatedData = {
                  title: e.target.title.value,
                  description: e.target.description.value,
                  price: e.target.price.value,
                  visibility: e.target.visibility.value,
                  sizes: e.target.sizes.value,
                  categoryId: e.target.categoryId.value,
                };
                handleUpdateProduct(product.id, updatedData);
              }}
            >
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Título
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  defaultValue={product.title}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Descripción
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  defaultValue={product.description}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="price" className="form-label">
                  Precio
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  defaultValue={product.price}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="visibility" className="form-label">
                  Visiblidad
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="visibility"
                  defaultValue={product.visibility}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="sizes" className="form-label">
                  Talles
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sizes"
                  defaultValue={product.sizes}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="categoryId" className="form-label">
                  CategoryId
                </label>
                <input
                  type="int"
                  className="form-control"
                  id="categoryId"
                  defaultValue={product.categoryId}
                />
              </div>
              {/* Otros campos como visibility y sizes */}
              {/* Agregar más campos según los datos del producto */}
              <button type="submit" className="btn btn-primary me-2">
                Guardar
              </button>
            </form>
            {/* Botón para eliminar el producto */}
            <button
              className="btn btn-danger"
              onClick={() => handleDeleteProduct(product.id)}
            >
              {product.visibility == 1 ? "BORRAR" : "RESTAURAR"}
            </button>
            {/* Mostrar las imágenes del producto */}
            <div className="mt-3 d-flex">
              {product.images.map((image) => (
                <div
                  className="d-flex flex-column m-2"
                  style={
                    image.path.includes("cloudinary")
                      ? {
                          border: "6px solid green",
                          padding: "5px",
                          borderRadius: "10px",
                        }
                      : {}
                  }
                >
                  <img
                    src={image.path}
                    alt="Product Image"
                    className="img-thumbnail me-2 hovRed"
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                    onClick={() => handleDeleteImage(product.id, image.id)}
                  />
                  {image.visibility == 1 ? (
                    <button onClick={() => handleUpdateImage(image.id)}>
                      Hacer Portada
                    </button>
                  ) : (
                    <p className="text-center text-success">
                      <b>PORTADA</b>
                    </p>
                  )}
                </div>
              ))}
            </div>
            <div>
              <input
                className="mt-4 w-100"
                type="file"
                onChange={handleImageUpload}
                id="fileInput"
              />
              <button
                className="mt-4 w-100 btn btn-primary"
                onClick={() => uploadImage(product.id)}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GestionProducts;
