import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setLoading } from "../redux/loadingSlice";
import axios from "axios";
import QRCode from "qrcode.react";
import { emptyCart } from "../redux/cartSlice";

function OrderCreated() {
  const { orderId } = useParams();
  const [status, setStatus] = useState();
  const [total, setTotal] = useState();
  const [init_point, setInitPoint] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderInfo = async () => {
      dispatch(setLoading(true));
      try {
        const response = await axios.get(
          `https://boltrev-api.vercel.app/order/${orderId}`
        );
        if (response.data.status === "APROBADA") {
          dispatch(emptyCart());
        }
        setStatus(response.data.status);
        setTotal(response.data.total);
        setInitPoint(response.data.init_point);
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    };
    fetchOrderInfo();
  }, [dispatch, orderId]);

  return (
    <div id="OrderCreated">
      <div className="payBlock mt-5">
        <h1>Orden #{orderId}</h1>
        {status === "APROBADA" ? (
          <span class="badge text-bg-success">Payed</span>
        ) : (
          <span class="badge text-bg-warning">Pending</span>
        )}
        <p className="mt-3">
          Total: <b> ${total}</b>
        </p>
        {status === "EN ESPERA DE PAGO" ? (
          <>
            <hr />
            <a target="_blank" href={init_point}>
              <button className="btn-mp w-100">PAGAR CON MERCADOPAGO</button>
            </a>
          </>
        ) : (
          <></>
        )}
        {status === "APROBADA" ? (
          <>
            <p>¡Gracias por tu compra! Tu pago ha sido confirmado.</p>
            <p>En instantes nos comunicaremos para coordinar la entrega</p>
          </>
        ) : (
          <></>
        )}
        <button
          onClick={() => {
            window.open(
              `https://wa.me/+59891265817?text=Me gustaría saber más de mi orden num: ${orderId}`,
              "_blank"
            );
          }}
          className="wsp mt-3 w-100"
        >
          ¿ NECESITAS AYUDA ? <i className="fa-brands fa-whatsapp"></i>
        </button>
      </div>
    </div>
  );
}

export default OrderCreated;
