import { createSlice } from "@reduxjs/toolkit";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("cart");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("cart", serializedState);
  } catch {
    // ignore write errors
  }
};

const cartSlice = createSlice({
  name: "cart",
  initialState: loadState() || {
    items: [],
  },
  reducers: {
    addToCart(state, action) {
      state.items.push(action.payload);
      saveState(state);
    },
    removeFromCart(state, action) {
      state.items = state.items.filter(
        (item) => item.cartId !== action.payload
      );
      saveState(state);
    },
    emptyCart(state, action) {
      state.items = [];
      saveState(state);
    },
  },
});

export const { addToCart, removeFromCart, emptyCart } = cartSlice.actions;

export default cartSlice.reducer;
