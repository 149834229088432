import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";

function Book() {
  const [images, setImages] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const imagesRes = await axios.get(
          "https://boltrev-api.vercel.app/images"
        );
        setImages(imagesRes.data);
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    };

    fetchData();
  }, [dispatch]);
  return (
    <div id="Book">
      {images && images.map((img) => <img key={img.path} src={img.path} />)}
    </div>
  );
}

export default Book;
