import React, { useState } from "react";
import blog from "../../images/blog.png";

function Blog() {
  const [openBlog, setOpenBlog] = useState();
  return (
    <div id="Blog">
      <div className="container">
        <img src={blog} alt="Blog" />

        <h1
          onClick={() => {
            if (openBlog != "GCMCMR") {
              setOpenBlog("GCMCMR");
            } else {
              setOpenBlog("");
            }
          }}
        >
          Guía Completa de Marketing para Crear tu Marca de Ropa
        </h1>

        {openBlog == "GCMCMR" && (
          <>
            <h2>Crea el Mejor Producto Posible</h2>

            <p>
              <b>Crea un Concepto Creativo de Marca</b>
            </p>
            <p>
              Tu marca debe tener un concepto claro y distintivo. Este será el
              alma de tu producto y lo que lo diferenciará en el mercado. Piensa
              en los valores, la historia y el mensaje que quieres transmitir.
            </p>

            <p>
              <b>Define tu Target Objetivo</b>
            </p>
            <p>
              Identifica quiénes son tus clientes ideales. ¿Qué edad tienen?
              ¿Cuáles son sus intereses y comportamientos de compra? Cuanto más
              específico seas, mejor podrás enfocar tus esfuerzos de marketing.
            </p>

            <p>
              <b>Entiende Qué Productos Quiere tu Target</b>
            </p>
            <p>
              Investiga y comprende qué tipos de productos son más atractivos
              para tu público objetivo. Analiza tendencias y comportamientos de
              compra.
            </p>

            <p>
              <b>Diseña el Producto/Diseño</b>
            </p>
            <p>
              Utiliza mockups para visualizar y perfeccionar tus diseños.
              Descarga mockups que te permitan ver cómo se verá tu producto
              final antes de producirlo.
            </p>

            <p>
              <b>Busca un Proveedor para Crear este Producto</b>
            </p>
            <p>
              Investiga y selecciona proveedores confiables. Descarga una lista
              de proveedores potenciales y compara sus ofertas y capacidades.
            </p>

            <p>
              <b>Pide y Analiza una Muestra</b>
            </p>
            <p>
              Antes de hacer un pedido grande, solicita una muestra del
              producto. Evalúa la calidad, los materiales y el acabado.
            </p>

            <p>
              <b>Pide el Bulk Order</b>
            </p>
            <p>
              Una vez que estés satisfecho con la muestra, realiza un pedido en
              grandes cantidades para comenzar tu producción.
            </p>

            <p>
              <strong>Importante:</strong>
            </p>
            <ul>
              <li>Busca elementos que te diferencien de tu competencia.</li>
              <li>
                Aprende todos los materiales, estampaciones y elementos con los
                que puedas jugar.
              </li>
              <li>No copies a nadie, crea tu propio producto y serás único.</li>
            </ul>

            <h2>Estrategia de Contenido Orgánico</h2>

            <p>
              <b>¿Cómo Crear Videos Virales?</b>
            </p>
            <ul>
              <li>
                <strong>Analiza Tendencias:</strong> Utiliza TikTok para
                analizar tu competencia. Busca términos como "clothing brand",
                "streetwear" o términos relacionados con tu nicho. Filtra por
                los videos más vistos en los últimos 6 meses.
              </li>
              <li>
                <strong>Inspírate:</strong> Toma nota de cómo son los primeros 3
                segundos de estos videos y qué elementos llaman la atención de
                tantas personas.
              </li>
              <li>
                <strong>No Reinventes la Rueda:</strong> Crea videos similares a
                los que han funcionado anteriormente ya que las plataformas
                prefieren contenido que retenga a los usuarios.
              </li>
              <li>
                <strong>Postea Frecuentemente:</strong> Pública 2-3 videos al
                día. Cuantos más videos publiques, más probabilidades tendrás de
                crear un video viral.
              </li>
              <li>
                <strong>Analiza y repite:</strong> Observa qué elementos han
                funcionado en tus videos y repítelos en otros formatos para
                mantener un flujo constante de videos virales.
              </li>
              <li>
                <strong>Interactúa con tu audiencia:</strong> Responde a los
                comentarios utilizando la función de respuesta por video en
                TikTok. Esto no solo genera nuevo contenido sino también fomenta
                la interacción con tu audiencia.
              </li>
            </ul>

            <p>
              <b>Trabajar con Influencers y Creadores de Contenido</b>
            </p>

            <p>
              <b>Micro Influencers (5-50K seguidores)</b>
            </p>
            <ul>
              <li>
                <strong>Promoción sin Pago Directo:</strong> Envía productos a
                micro influencers a cambio de promoción.
              </li>
              <li>
                <strong>Segmentación:</strong> Identifica y segmenta tu público
                objetivo.
              </li>
              <li>
                <strong>Contacto:</strong> Contacta a muchos influencers para
                aumentar las posibilidades de respuesta.
              </li>
              <li>
                <strong>Prueba Social:</strong> Enviar productos inicialmente
                para obtener prueba social.
              </li>
            </ul>

            <p>
              <b>Macro Influencers</b>
            </p>
            <ul>
              <li>
                <strong>Difícil de Persuadir:</strong> Los macro influencers
                requieren creatividad y un producto excepcional.
              </li>
              <li>
                <strong>Contacto Inicial:</strong> Contactarlos por correo o DM
                para ofrecerles productos sin pago.
              </li>
              <li>
                <strong>Captura de Interacción:</strong> Captura su interacción
                en video para maximizar la exposición.
              </li>
            </ul>

            <h2>Estrategia de Anuncios</h2>

            <p>
              <b>¿Cómo Escalar tu Negocio?</b>
            </p>
            <ul>
              <li>
                <strong>Videos Virales y Seguidores:</strong> Una vez que tienes
                videos virales y un número significativo de seguidores con
                ventas regulares, es el momento de escalar.
              </li>
              <li>
                <strong>Anuncios en Redes Sociales:</strong> Utiliza anuncios en
                Instagram, Facebook, TikTok o Pinterest para multiplicar tu
                negocio.
              </li>
              <li>
                <strong>Creativo de los Anuncios:</strong> El elemento visual
                (video o imagen) es crucial. Usa videos virales con un CTA final
                de compra.
              </li>
              <li>
                <strong>Premio por la Plataforma:</strong> Las plataformas
                premiarán tus anuncios con un menor coste por ser similares a
                contenido orgánico.
              </li>
            </ul>

            <p>
              <strong>Pro Tip:</strong>
            </p>
            <p>
              Antes de escalar tu negocio, crea una estrategia para no quedarte
              sin producto demasiado rápido. Un error común es quedarte sin
              stock por predecir erróneamente las ventas.
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default Blog;
