import React from "react";
import { useNavigate } from "react-router-dom";

function BoltHouse() {
  const navigate = useNavigate();
  return (
    <div id="BoltHouse">
      <div className="blackScreen"></div>
      <div className="screen">
        <div id="wrapper">
          <div
            onClick={() => navigate("/rooms/market")}
            id="marketMarket"
            className="page"
          ></div>
          <div
            onClick={() => navigate("/rooms/renata")}
            id="renata"
            className="page"
          ></div>
          <div
            onClick={() => navigate("/rooms/blog")}
            id="blog"
            className="page"
          ></div>
          <div
            onClick={() => navigate("/rooms/book")}
            id="boltBook"
            className="page"
          ></div>
          <div
            onClick={() => navigate("/rooms/exlusive")}
            id="exlusive"
            className="page"
          ></div>
        </div>
      </div>
    </div>
  );
}

export default BoltHouse;
