import React from "react";

function Renata() {
  return (
    <div id="Book">
      <div className="container pt-5 pb-5">
        <h1>Renata x BOLT</h1>
        <p>
          Mi nombre es Renata, soy periodista y productora de moda. Soy
          uruguaya, y eso es importante porque el enfoque de mi trabajo tiene
          que ver con la cultura local y la industria nacional. A través de la
          moda y la creatividad, trato de mostrar un poco de cómo veo el mundo
          y, por qué no, también cómo me imagino el futuro de la moda.
        </p>
        <p>
          Estoy convencida de que la moda se construye en comunidad, de manera
          circular y sostenible. Es por eso que soy parte de la comunidad de
          BOLT.
        </p>
        <p>
          Espero que disfruten mi trabajo y estoy siempre disponible para seguir
          creando.
        </p>
        <b>Renata.</b>
      </div>
      <div className="bookImage">
        {[
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627874/bolt/hursv6j1inwvtklhdqek.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627875/bolt/eur0yzegknqhjhrcf10p.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627876/bolt/ifqcrocoz8pnldh7wxfs.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627876/bolt/yib3ypbmxnn6sx1tvbvi.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627877/bolt/ragqoqelvvniaomikhc7.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627878/bolt/w29lq5ehhge04sohilzr.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627878/bolt/baqpdrpsbbjlmrfbaxym.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627880/bolt/vyrpz9imyfn8r6igvyxs.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627882/bolt/ysjbvesfvwi49oxyfdwt.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627884/bolt/cksw0vwmei1oqsm4s3vf.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627884/bolt/pzttmhjisdhoo90l4zkn.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627885/bolt/blhiaauwtuv3jpxnkm7t.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627887/bolt/i8cpdsoqua7qh4s4jnoh.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627888/bolt/zzawxzwmquo168pkbixo.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627889/bolt/q8pwwopsjbwzp6jbjjtl.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627891/bolt/epbyalsq5p7jzsi4pkm8.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627892/bolt/xckhe5qwuehthsetbvty.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627892/bolt/dvbzsxc4ddozx3rm9pnk.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627893/bolt/ctqormm0lyg2upsltycz.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627894/bolt/zgy3m0b1lfn6vlxbd57g.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627895/bolt/gt3ntnbuxxofmfohksvq.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627895/bolt/pjrjwxvxklrylbiwgg9j.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627897/bolt/fvpzudmeeua5dxkhn3qt.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627898/bolt/g3bnvyi0or0ksoldv63b.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627899/bolt/i8ejygngy4onwdf7tpyv.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627899/bolt/lieq2dczjbq4iulezmiq.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627901/bolt/n5mqgehe3kbddqzfboby.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627901/bolt/upgpjabaegabbhzdxy1z.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627902/bolt/hevwve5qiuttkgta4iyv.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627903/bolt/xbqsoajjquuhk1rwqhnq.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627904/bolt/trlgcp4xpckdm4hu1jco.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627905/bolt/trelyrnyowkrvbg9fhsv.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627906/bolt/ulwxyvuqls2cevlmetev.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627907/bolt/pt1gfddxmltmzpazm7fz.jpg",
          "https://res.cloudinary.com/dazn99jie/image/upload/v1726627908/bolt/kzstz4tc3kyk0ciuuafv.jpg",
        ].map((img) => (
          <img key={img} src={img} />
        ))}
      </div>
    </div>
  );
}

export default Renata;
