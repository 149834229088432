import React, { useEffect } from "react";

function BoltHouseLoad() {
  useEffect(() => {
    window.location.pathname = "/houseLoader.html";
  }, []);

  return <div></div>;
}

export default BoltHouseLoad;
