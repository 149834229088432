import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";

const Logout = () => {
  useEffect(() => {
    Cookies.remove("token");
    Cookies.remove("userName");
    window.location.href = "/";
  }, []);

  return (
    <div id="loader">
      <CircularProgress size={60} />
    </div>
  );
};

export default Logout;
