import { useState, useEffect } from "react";
import logo from "../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart } from "../redux/cartSlice";

function Nav({ openCart, setOpenCart }) {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);
  const [name, setName] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const reduxCart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  //Estados
  useEffect(() => {
    const userName = Cookies.get("userName");
    if (userName) {
      setName(userName);
    } else {
      setName("");
    }
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };
  const handleRemoveFromCart = (cartId) => {
    dispatch(removeFromCart(cartId));
  };
  //Funciones
  const isMobile = useIsMobile();
  const listItemStyle = {
    padding: "16px", // Espaciado interno
    borderTop: "1px solid #ddd", // Borde superior
    borderBottom: "1px solid #ddd", // Borde inferior
  };
  const handleLongPress = () => {
    window.location.href = "/bolt-house";
  };
  const handleMouseDown = () => {
    const timeout = setTimeout(handleLongPress, 3000);
    setTimer(timeout);
  };
  const handleMouseUp = () => {
    clearTimeout(timer);
  };

  const menuItems = (
    <List>
      {name ? (
        <>
          <ListItem component={Link} to="/profile" onClick={handleDrawerToggle}>
            <ListItemText primary="Perfil" />
          </ListItem>
          <Divider />
          <ListItem component={Link} to="/logout" onClick={handleDrawerToggle}>
            <ListItemText primary="Logout" />
          </ListItem>
        </>
      ) : (
        <>
          <ListItem component={Link} to="/login" onClick={handleDrawerToggle}>
            <ListItemText primary="LOGIN" />
          </ListItem>
          <Divider />
        </>
      )}
    </List>
  );

  const drawerItems = (
    <List style={{ padding: "0" }}>
      <ListItem
        component={Link}
        to="/products-list"
        onClick={handleDrawerToggle}
        style={listItemStyle}
      >
        <ListItemText primary="Shop All" />
      </ListItem>
      {name ? (
        <>
          <ListItem
            component={Link}
            to="/profile"
            onClick={handleDrawerToggle}
            style={listItemStyle}
          >
            <ListItemText primary="Perfil" />
          </ListItem>
          <ListItem
            component={Link}
            to="/logout"
            onClick={handleDrawerToggle}
            style={listItemStyle}
          >
            <ListItemText primary="Logout" />
          </ListItem>
        </>
      ) : (
        <>
          <ListItem
            component={Link}
            to="/login"
            onClick={handleDrawerToggle}
            style={listItemStyle}
          >
            <ListItemText primary="Login" />
          </ListItem>
        </>
      )}
      <ListItem
        component={Link}
        to="/menu"
        onClick={handleDrawerToggle}
        style={listItemStyle}
      >
        <ListItemText primary="Tiendas" />
      </ListItem>
      <ListItem
        component={Link}
        to="/bolt-house"
        onClick={handleDrawerToggle}
        style={{ ...listItemStyle, backgroundColor: "red" }}
      >
        <ListItemText primary="House" />
      </ListItem>
    </List>
  );
  const cartDrawer = (
    <div className="container-fluid mt-4">
      <h3 className="text-center mb-5">Carrito</h3>
      <List style={{ padding: "0" }}>
        {reduxCart.items.length === 0 && (
          <h5 className="text-center">Carrito de compras vacio</h5>
        )}
        {reduxCart.items.map((item) => (
          <ListItem key={item.cartId} style={listItemStyle}>
            <div className="row">
              <figure className="col-4">
                <img
                  src={item.images[0] ? item.images[0].path : ""}
                  alt={`Imagen del producto ${item.title}`}
                />
              </figure>
              <div className="col-8">
                <h4>{item.title}</h4>
                <h6>
                  <div>
                    {item.sale && <del>${item.sale}</del>}{" "}
                    <span>${item.price}</span>
                  </div>
                </h6>
                <h6>Talla: {item.productNote}</h6>
                <hr />
                <button onClick={() => handleRemoveFromCart(item.cartId)}>
                  Borrar
                </button>
              </div>
            </div>
          </ListItem>
        ))}
        <ListItem>
          <Link
            onClick={() => setOpenCart(false)}
            to="/create-order"
            className="w-100"
          >
            <button className="my-5 p-3 w-100 d-flex justify-content-between">
              COMPRAR{" "}
              <span>
                $
                {reduxCart.items.reduce((total, item) => total + item.price, 0)}
              </span>
            </button>
          </Link>
        </ListItem>
      </List>
    </div>
  );
  //Constantes

  return (
    <nav id="navbar">
      {/* <div
        style={
          window.location.pathname === "/"
            ? { display: "none" }
            : { display: "flex" }
        }
        onClick={() => navigate("bolt-house-load")}
        className="openBoltHouse"
      >
        B
      </div> */}
      <div className="container-fluid">
        <figure
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
        >
          <Link to="/products-list">
            <img src={logo} alt="Logo de Bolt Market" />
          </Link>
        </figure>
        <Drawer
          anchor="right"
          open={openCart}
          onClose={() => {
            setOpenCart(false);
          }}
          variant="temporary"
        >
          <div className="carritoCompras">{cartDrawer}</div>
        </Drawer>
        {isMobile ? (
          <>
            <ul>
              <li onClick={handleDrawerToggle}>
                <i className="fa-lg fa-solid fa-bars"></i>
              </li>
              <li onClick={() => setOpenCart(true)}>
                <i className="fa-lg fa-solid fa-bag-shopping position-relative">
                  <span className=" cartCounter">
                    {" "}
                    {reduxCart.items.length}
                  </span>
                </i>
              </li>
            </ul>

            <Drawer
              anchor="right"
              open={openDrawer}
              onClose={handleDrawerToggle}
              variant="temporary"
            >
              <div style={{ width: "90vw" }}>{drawerItems}</div>
            </Drawer>
          </>
        ) : (
          <ul>
            <li>
              <Button
                style={{ marginRight: "20px" }}
                id="basic-button"
                className="house-button"
                aria-controls={anchorEl ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? "true" : undefined}
                onClick={() => navigate("/bolt-house")}
              >
                HOUSE
              </Button>
              {name ? (
                <Button
                  id="basic-button"
                  aria-controls={anchorEl ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorEl ? "true" : undefined}
                  onClick={handleMenuClick}
                >
                  {name}
                </Button>
              ) : (
                <Link to="/login">LOGIN</Link>
              )}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {menuItems}
              </Menu>
            </li>
            <li>
              <Link to="/menu">
                <i className="fa-lg fa-solid fa-store"></i>
              </Link>
            </li>
            <li onClick={() => setOpenCart(true)}>
              <i className="fa-lg fa-solid fa-bag-shopping position-relative">
                <span className="cartCounter"> {reduxCart.items.length}</span>
              </i>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}

export default Nav;

// Función para detectar si es móvil
function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600); // Consideramos móvil si el ancho de la ventana es menor que 600px
    };

    handleResize(); // Llamamos a la función una vez para establecer el estado inicial

    window.addEventListener("resize", handleResize); // Agregamos un event listener para actualizar el estado cuando cambia el tamaño de la ventana

    return () => {
      window.removeEventListener("resize", handleResize); // Limpiamos el event listener cuando el componente se desmonta
    };
  }, []);

  return isMobile;
}
