import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadingSlice";
import { Link, useParams } from "react-router-dom";

function StoreCommerce() {
  const { storeId } = useParams();
  const [products, setProducts] = useState();
  const [store, setStore] = useState();
  const [actualPage, setActualPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const productsResponse = await axios.get(
          `https://boltrev-api.vercel.app/products?store=${storeId}`
        );
        setProducts(productsResponse.data.products);
        const storeResponse = await axios.get(
          `https://boltrev-api.vercel.app/store/${storeId}`
        );
        setStore(storeResponse.data);
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    };
    fetchData();
  }, [dispatch, storeId]);

  return (
    <div id="ProductsList">
      {store && (
        <div className="position-relative d-flex justify-content-center align-items-center">
          <h1
            style={{ color: "white", zIndex: 10 }}
            className="mt-4 text-center position-absolute"
          >
            {store.title}
          </h1>
          <img
            className="bannerCommerce"
            src={store.banner}
            alt={`Banner de la tienda ${store.banner}`}
          />
        </div>
      )}
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-12">
            <div className="row">
              {products &&
                products.map((product, index) => (
                  <Link
                    to={`/product/${product.id}`}
                    className="col-6 col-lg-3 mb-5"
                    key={product.id}
                  >
                    <article>
                      <figure style={{ overflow: "hidden" }} className="mb-1">
                        <img
                          className="listImg"
                          alt={`Imagen de product ${product.title}`}
                          src={product.images[0] ? product.images[0].path : ""}
                        />
                      </figure>
                      <div>
                        <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {product.title}
                        </div>
                        <div style={{ fontSize: "10px", fontWeight: "bold" }}>
                          {product.sale && <del>${product.sale}</del>}{" "}
                          <span>${product.price}</span>
                        </div>
                      </div>
                    </article>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreCommerce;
