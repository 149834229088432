import React, { useEffect, useRef } from "react";
import Flashes from "../images/Flashes.mp4";
import { Link, useNavigate } from "react-router-dom";

function Banner() {
  const vidRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    vidRef.current.play();
    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);
  const handleIframeMessage = (event) => {
    if (event.data === "iframeBodyClick") {
      navigate("products-list");
    }
  };
  return (
    <div id="banner">
      <div className="d-md-none">
        <Link to="products-list">
          <figure className="mb-0">
            <video
              className="videoBanner"
              ref={vidRef}
              muted
              loop
              playsInline
              autoPlay={true}
            >
              <source src={Flashes} type="video/mp4" />
            </video>
          </figure>
          <figure className="mb-0">
            <img
              src="https://res.cloudinary.com/dazn99jie/image/upload/v1726627452/bolt/qk0lsijvyrghsfdlblxq.png"
              alt="Bolt Verde"
            />
          </figure>
          <figure className="mb-0">
            <img
              src="https://res.cloudinary.com/dazn99jie/image/upload/v1726627480/bolt/dhf8ig83ylupjuudvrqx.png"
              alt="Bolt Verde"
            />
          </figure>
        </Link>
      </div>
      <div className="d-none d-md-block">
        <iframe
          src="/logo.html"
          title="Bolt Logo"
          className="logoIframe"
          scrolling="no"
        />
      </div>
    </div>
  );
}

export default Banner;
