import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadingSlice";

function Profile() {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [code, setCode] = useState();
  //Estados
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Constantes
  useEffect(() => {
    const fetchUserInfo = async () => {
      dispatch(setLoading(true));
      try {
        const response = await axios.get(
          "https://boltrev-api.vercel.app/authentication/user-info",
          {
            headers: {
              Authorization: `${Cookies.get("token")}`,
            },
          }
        );
        setUser(response.data);
        dispatch(setLoading(false));
      } catch (error) {
        navigate("/login");
        dispatch(setLoading(false));
      }
    };
    fetchUserInfo();
  }, [navigate, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    try {
      await axios
        .put(
          "https://boltrev-api.vercel.app/authentication/update-user",
          user,
          {
            headers: {
              Authorization: `${Cookies.get("token")}`,
            },
          }
        )
        .then((result) => {
          window.location.reload();
        });
    } catch (error) {
      setError(error.response.data.message);
      dispatch(setLoading(false));
    }
  };

  const handleValidator = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    try {
      await axios
        .put(
          "https://boltrev-api.vercel.app/authentication/valid-code",
          { code },
          {
            headers: {
              Authorization: `${Cookies.get("token")}`,
            },
          }
        )
        .then((result) => {
          window.location.reload();
        });
    } catch (error) {
      setError("Codigo Invalido");
      dispatch(setLoading(false));
    }
  };
  //Funciones

  return (
    <div id="profile">
      {user && (
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="register-box">
                <h1 style={{ textTransform: "capitalize" }}>
                  Saldo: {user.points} BPs
                </h1>
                <hr />
                {!user.valid ? (
                  <>
                    <div className="alert alert-warning" role="alert">
                      Le solicitamos que valide su correo electrónico para
                      completar el proceso de registro. Por favor, revise su
                      bandeja de entrada, donde hemos enviado un código
                      verificador necesario para continuar.
                    </div>
                    <form onSubmit={handleValidator}>
                      <input
                        type="text"
                        name="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Codigo"
                      />
                      {error && (
                        <div className="alert alert-warning" role="alert">
                          {error}
                        </div>
                      )}
                      <button type="submit">VALIDAR</button>
                    </form>
                  </>
                ) : (
                  <>
                    <p>Actualiza la informacion de tu perfil</p>
                    {error && (
                      <div className="alert alert-warning" role="alert">
                        {error}
                      </div>
                    )}
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        name="name"
                        value={user.name}
                        onChange={(e) =>
                          setUser({ ...user, name: e.target.value })
                        }
                        placeholder="Nombre"
                      />
                      <input
                        type="text"
                        name="address"
                        value={user.address}
                        onChange={(e) =>
                          setUser({ ...user, address: e.target.value })
                        }
                        placeholder="Dirección completa"
                      />
                      <input
                        type="text"
                        name="phone"
                        value={user.phone}
                        onChange={(e) =>
                          setUser({ ...user, phone: e.target.value })
                        }
                        placeholder="Celular"
                      />
                      <button type="submit">GUARDAR</button>
                    </form>
                  </>
                )}
                <button
                  onClick={() => {
                    window.open(
                      `https://wa.me/+59891265817?text=Necesito ayuda con mi perfil: ${user.email}`,
                      "_blank"
                    );
                  }}
                  className="wsp mt-3 mb-2"
                >
                  ¿ NECESITAS AYUDA ? <i className="fa-brands fa-whatsapp"></i>
                </button>
                <button
                  onClick={() => {
                    window.open(
                      `https://wa.me/+59891265817?text=Necesito recuperar los BPs de mi cuenta: ${user.email}`,
                      "_blank"
                    );
                  }}
                  className="wsp mt-3 mb-5"
                >
                  RECUPERAR BPs de Bolt 1.0{" "}
                  <i className="fa-brands fa-whatsapp"></i>
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center">
              <div id="license">
                <div className="left">
                  <div className="photo">
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src="https://i.pinimg.com/originals/30/8d/79/308d795c3cac0f8f16610f53df4e1005.jpg"
                      alt=""
                    />
                  </div>
                  <div className="barcode"></div>
                  <div className="address">
                    {user.name.substring(0, 12)}
                    <br />
                    {user.address.substring(0, 12)}
                    <br />
                    {user.phone.substring(0, 12)}
                  </div>
                </div>
                <div className="right">
                  <div className="header">
                    <div className="state">HAWAII</div>
                    <div className="docname">
                      BOLT <br /> LICENSE
                    </div>
                  </div>
                  <svg
                    opacity="0.8"
                    className="rainbow"
                    width="100%"
                    height="100%"
                  >
                    <path
                      fill="red"
                      d="M 0 0 q 140 0 336 160 l0 10 q -200 -140 -336 -160 "
                    />
                    <path
                      fill="orange"
                      d="M 0 10 q 140 0 336 160 l0 10 q -200 -140 -336 -160 "
                    />
                    <path
                      fill="yellow"
                      d="M 0 20 q 140 0 336 160 l0 10 q -200 -140 -336 -160 "
                    />
                    <path
                      fill="green"
                      d="M 0 30 q 140 0 336 160 l0 10 q -200 -140 -336 -160 "
                    />
                    <path
                      fill="lightblue"
                      d="M 0 40 q 140 0 336 160 l0 10 q -200 -140 -336 -160 "
                    />
                    <path
                      fill="blue"
                      d="M 0 50 q 140 0 336 160 l0 10 q -200 -140 -336 -160 "
                    />
                    <path
                      fill="#929"
                      d="M 0 61 q 140 0 336 160 l0 10 q -200 -150 -336 -160 "
                    />
                  </svg>
                  <div className="number">
                    <sup>NUMBER</sup>{" "}
                    <strong className="high">01-47-87114</strong>
                  </div>
                  <div className="dates">
                    <sup>DOB</sup> <strong className="high">03/06/1981</strong>{" "}
                    <sup>EXP</sup> <strong className="high">06/03/2008</strong>
                  </div>
                  <table>
                    <tr>
                      <th>HT</th>
                      <th>WT</th>
                      <th>HAIR</th>
                      <th>EYES</th>
                      <th>SEX</th>
                      <th>CTY</th>
                    </tr>
                    <tr>
                      <td>5-10</td>
                      <td>150</td>
                      <td>BRO</td>
                      <td>BRO</td>
                      <td>YES</td>
                      <td>0</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th>ISSUE DATE</th>
                      <th>CLASS</th>
                      <th>RESTR</th>
                      <th>ENDORSE</th>
                    </tr>
                    <tr>
                      <td>6/18/1998</td>
                      <td>3</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
