import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import Login from "./components/Login";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Profile from "./components/Profile";
import Banner from "./components/Banner";
import Logout from "./components/Logout";
import ProductsList from "./components/ProductsList";
import ProductDetail from "./components/ProductDetail";
import { useState } from "react";
import CreateOrder from "./components/CreateOrder";
import OrderCreated from "./components/OrderCreated";
import Orders from "./components/Orders";
import MenuIndex from "./components/MenuIndex";
import StoreCommerce from "./components/StoreCommerce";
import BoltHouse from "./components/BoltHouse";
import BoltHouseLoad from "./components/BoltHouseLoad";
import GestionProducts from "./components/GestionProducts";
import Market from "./components/Rooms/Market";
import Renata from "./components/Rooms/Renata";
import Blog from "./components/Rooms/Blog";
import Book from "./components/Rooms/Book";
import Exlusive from "./components/Rooms/Exlusive";
import CrearProducto from "./components/CreateProduct";

function Routing() {
  const loading = useSelector((state) => state.loading);
  const [openCart, setOpenCart] = useState(false);
  return (
    <Router>
      <div>
        <Nav setOpenCart={setOpenCart} openCart={openCart} />
        {loading && (
          <div id="loader">
            <CircularProgress size={60} />
          </div>
        )}
        <Routes>
          {/* <Route path="/" element={<Banner />} /> */}
          <Route path="/" element={<ProductsList />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/products-list" element={<ProductsList />} />
          <Route path="/store/:storeId" element={<StoreCommerce />} />
          <Route path="/create-order" element={<CreateOrder />} />
          <Route path="/order/:orderId" element={<OrderCreated />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/gestion-productos" element={<GestionProducts />} />
          <Route path="/creacion-producto" element={<CrearProducto />} />
          <Route path="/bolt-house" element={<BoltHouse />} />
          <Route path="/bolt-house-load" element={<BoltHouseLoad />} />
          <Route path="/menu" element={<MenuIndex />} />
          <Route
            path="/product/:id"
            element={<ProductDetail setOpenCart={setOpenCart} />}
          />

          <Route path="/rooms/market" element={<Market />} />
          <Route path="/rooms/renata" element={<Renata />} />
          <Route path="/rooms/blog" element={<Blog />} />
          <Route path="/rooms/book" element={<Book />} />
          <Route path="/rooms/exlusive" element={<Exlusive />} />

          <Route path="*" element={<div className="notfound">404</div>} />
        </Routes>
      </div>
    </Router>
  );
}

export default Routing;
