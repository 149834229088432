import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadingSlice";
import { addToCart } from "../redux/cartSlice";

function ProductDetail({ setOpenCart }) {
  const { id } = useParams();
  const [product, setProduct] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [notes, setNotes] = useState();
  //Estados
  const dispatch = useDispatch();
  //Constantes
  useEffect(() => {
    const fetchProductData = async () => {
      dispatch(setLoading(true));
      try {
        const response = await axios.get(
          `https://boltrev-api.vercel.app/products?id=${id}`
        );
        setProduct(response.data.products[0]);
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    };
    fetchProductData();
  }, [dispatch, id]);

  const handleAddToCart = (product) => {
    setNotes("");
    setSelectedSize();
    dispatch(
      addToCart({
        cartId: Math.random(),
        ...product,
        productNote: `${selectedSize}-${notes}`,
      })
    );
    setOpenCart(true);
  };
  return (
    <div id="ProductDetail">
      {product && (
        <div className="container-fluid">
          <div className="row">
            <div
              className="d-none d-md-grid col-8"
              style={{ gridTemplateColumns: "1fr 1fr" }}
            >
              {product.images.map((image) => (
                <div key={image.id}>
                  <img
                    src={image.path}
                    alt={`Imagen de product ${product.title}`}
                  />
                </div>
              ))}
            </div>
            <div className="d-block d-md-none col-12">
              <div className="fakeCarrousel">
                {product.images.map((image) => (
                  <img
                    key={image.id}
                    src={image.path}
                    alt={`Imagen de producto ${product.title}`}
                  />
                ))}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <h1>{product.title}</h1>
              <h4>
                <div>
                  {product.sale && <del>${product.sale}</del>}{" "}
                  <span>${product.price}</span>
                </div>
              </h4>
              <p>{product.description}</p>
              <hr />
              <h6 className="mb-3">
                Talle: {selectedSize} - {notes}
              </h6>
              <div className="size-selector">
                {product.sizes &&
                  product.sizes.split("-").map((size) => (
                    <div
                      className={selectedSize === size ? "sizeSelected" : ""}
                      onClick={() => {
                        setSelectedSize(size);
                        setNotes("");
                      }}
                      key={size}
                    >
                      {size}
                    </div>
                  ))}
              </div>
              <textarea
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                className="mt-3 p-2 w-100"
                placeholder="Notas para el producto"
                value={notes}
              ></textarea>
              <button
                onClick={() => {
                  handleAddToCart(product);
                }}
                disabled={!selectedSize}
                className="w-100 mt-5"
              >
                AGREGAR AL CARRITO
              </button>
              <button
                onClick={() => {
                  window.open(
                    `https://wa.me/+59891265817?text=Me gustaría saber más del producto ${product.title}`,
                    "_blank"
                  );
                }}
                className="w-100 mt-3 mb-5 wsp"
              >
                QUIERO MÁS INFO <i className="fa-brands fa-whatsapp"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductDetail;
