import axios from "axios";
import React, { useEffect, useState } from "react";

function MenuIndex() {
  const [stores, setStores] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 800) {
      window.location.pathname = "/ps4.html";
    } else {
      axios.get("https://boltrev-api.vercel.app/stores").then((result) => {
        setStores(result.data.stores);
      });
    }
  }, [window.innerWidth]);

  return (
    <div>
      {stores && (
        <div className="d-flex flex-wrap">
          {stores.map((store) => (
            <a href={`/store/${store.id}`} className="menuItemStore">
              <span className="menuItemStoreTitle">{store.title}</span>
              <figure className="mb-0">
                <img
                  src={store.banner ? store.banner : ""}
                  alt="Banner de tienda"
                />
              </figure>
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

export default MenuIndex;
