import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadingSlice";
import { Link } from "react-router-dom";
import { Drawer } from "@mui/material";
import Cookies from "js-cookie";
import unete from "../images/unete.png";

function ProductsList() {
  const [products, setProducts] = useState();
  const [categories, setCategories] = useState();
  const [stores, setStores] = useState();
  const [actualPage, setActualPage] = useState(1);
  const [categorySelected, setCategorySelected] = useState("");
  const [storeSelected, setStoreSelected] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const dispatch = useDispatch();
  const [canvas, setCanvas] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const productsResponse = await axios.get(
          "https://boltrev-api.vercel.app/products"
        );
        setProducts(productsResponse.data.products);

        const categoriesResponse = await axios.get(
          "https://boltrev-api.vercel.app/categories"
        );
        setCategories(categoriesResponse.data.categories);

        const storesResponse = await axios.get(
          "https://boltrev-api.vercel.app/stores"
        );
        setStores(storesResponse.data.stores);

        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    };

    fetchData();
  }, [dispatch]);

  const handleCategoryClick = async (categoryId) => {
    dispatch(setLoading(true));
    try {
      setCategorySelected(categoryId);
      setStoreSelected("");
      setActualPage(1);
      const response = await axios.get(
        `https://boltrev-api.vercel.app/products?category=${categoryId}`
      );
      setProducts(response.data.products);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const handleStoreClick = async (storeId) => {
    dispatch(setLoading(true));
    try {
      setStoreSelected(storeId);
      setCategorySelected("");
      setActualPage(1);
      const response = await axios.get(
        `https://boltrev-api.vercel.app/products?store=${storeId}`
      );
      setProducts(response.data.products);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const handleShowAllProducts = async () => {
    dispatch(setLoading(true));
    try {
      setCategorySelected("");
      setStoreSelected("");
      setActualPage(1);
      const response = await axios.get(
        `https://boltrev-api.vercel.app/products`
      );
      setProducts(response.data.products);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const filterDrawer = (
    <div className="container-fluid mt-4">
      <h3 className="text-center mb-5">Filtrar</h3>
      <ul className="filterList">
        <li
          style={
            !categorySelected && !storeSelected ? { fontWeight: "bold" } : {}
          }
          onClick={() => {
            handleShowAllProducts();
            setOpenFilter(false);
          }}
        >
          Todo
        </li>
        <hr />
        {categories &&
          categories.map((category) => (
            <li
              style={
                categorySelected === category.id ? { fontWeight: "bold" } : {}
              }
              onClick={() => {
                handleCategoryClick(category.id);
                setOpenFilter(false);
              }}
              key={category.id}
            >
              {category.title}
            </li>
          ))}
        <hr />
        {stores &&
          stores.map((store) => (
            <li
              style={storeSelected === store.id ? { fontWeight: "bold" } : {}}
              onClick={() => {
                handleStoreClick(store.id);
                setOpenFilter(false);
              }}
              key={store.id}
            >
              {store.title}
            </li>
          ))}
      </ul>
    </div>
  );

  return (
    <div id="ProductsList">
      {!Cookies.get("userName") && !Cookies.get("canvas") && !canvas && (
        <div className="uneteCanvas">
          <figure>
            <img
              onClick={() => {
                Cookies.set("canvas", true, { expires: 2 });
                window.location.href = "/login";
              }}
              src={unete}
              alt="Unete a Bolt Market"
            />
            <div
              onClick={() => {
                Cookies.set("canvas", true, { expires: 2 });
                setCanvas(true);
              }}
              className="closeCanvas"
            >
              X
            </div>
          </figure>
        </div>
      )}
      <div className="container-fluid">
        <div className="d-flex justify-content-center d-md-none mt-5">
          <Drawer
            anchor="top"
            open={openFilter}
            onClose={() => {
              setOpenFilter(false);
            }}
            variant="temporary"
          >
            <div>{filterDrawer}</div>
          </Drawer>
          <button onClick={() => setOpenFilter(true)}>FILTRAR</button>
        </div>
        <div className="row mt-5">
          <div className="col-2 col-md-4 col-lg-2 d-none d-md-block">
            <ul className="filterList">
              <li
                style={
                  !categorySelected && !storeSelected
                    ? { fontWeight: "bold" }
                    : {}
                }
                onClick={() => handleShowAllProducts()}
              >
                Todo
              </li>
              <hr />
              {categories &&
                categories.map((category) => (
                  <li
                    style={
                      categorySelected === category.id
                        ? { fontWeight: "bold" }
                        : {}
                    }
                    onClick={() => handleCategoryClick(category.id)}
                    key={category.id}
                  >
                    {category.title}
                  </li>
                ))}
              <hr />
              {stores &&
                stores.map((store) => (
                  <li
                    style={
                      storeSelected === store.id ? { fontWeight: "bold" } : {}
                    }
                    onClick={() => handleStoreClick(store.id)}
                    key={store.id}
                  >
                    {store.title}
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-12 col-md-8 col-lg-10">
            <div className="row">
              {products &&
                products.map((product, index) => (
                  <Link
                    to={`/product/${product.id}`}
                    className="col-6 col-lg-3 mb-5"
                    key={product.id}
                  >
                    <article>
                      <figure style={{ overflow: "hidden" }} className="mb-1">
                        <img
                          className="listImg"
                          alt={`Imagen de product ${product.title}`}
                          src={product.images[0] ? product.images[0].path : ""}
                        />
                      </figure>
                      <div>
                        <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {product.title}
                        </div>
                        <div style={{ fontSize: "10px", fontWeight: "bold" }}>
                          {product.sale && <del>${product.sale}</del>}{" "}
                          <span>${product.price}</span>
                        </div>
                      </div>
                    </article>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsList;
