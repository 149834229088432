import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadingSlice";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const [errorMessageRegister, setErrorMessageRegister] = useState("");
  const [errorMessageLogin, setErrorMessageLogin] = useState("");
  const [registerData, setRegisterData] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    password: "",
  });

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  //Estados
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Constantes
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      navigate("/logout");
    }
  }, [navigate]);

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    try {
      await axios
        .post(
          "https://boltrev-api.vercel.app/authentication/register",
          registerData
        )
        .then((result) => {
          Cookies.set("token", result.data.token, { expires: 2 });
          Cookies.set("userName", result.data.name, { expires: 2 });
          dispatch(setLoading(false));
          window.location.href = "/profile";
        });
    } catch (error) {
      setErrorMessageRegister(error.response.data.message);
      dispatch(setLoading(false));
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    try {
      await axios
        .post("https://boltrev-api.vercel.app/authentication/login", loginData)
        .then((result) => {
          Cookies.set("token", result.data.token, { expires: 2 });
          Cookies.set("userName", result.data.name, { expires: 2 });
          dispatch(setLoading(false));
          window.location.href = "/";
        });
    } catch (error) {
      setErrorMessageLogin(error.response.data.message);
      dispatch(setLoading(false));
    }
  };
  //Funciones

  return (
    <div id="login">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <div className="register-box">
              <h1>Login</h1>
              <p>
                Accede a tu cuenta para comenzar a disfrutar de nuestros
                servicios
              </p>
              {errorMessageLogin && (
                <div className="alert alert-warning" role="alert">
                  {errorMessageLogin}
                </div>
              )}
              <form onSubmit={handleLoginSubmit}>
                <input
                  type="email"
                  name="email"
                  value={loginData.email}
                  onChange={handleLoginChange}
                  placeholder="Email"
                />
                <input
                  type="password"
                  name="password"
                  value={loginData.password}
                  onChange={handleLoginChange}
                  placeholder="Contraseña"
                />
                <button type="submit">LOGIN</button>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="register-box">
              <h1>Regístrate</h1>
              <p>
                Crea una cuenta gratuita para acceder a contenido exclusivo y
                funcionalidades adicionales
              </p>
              {errorMessageRegister && (
                <div className="alert alert-warning" role="alert">
                  {errorMessageRegister}
                </div>
              )}
              <form onSubmit={handleRegisterSubmit}>
                <input
                  type="text"
                  name="name"
                  value={registerData.name}
                  onChange={handleRegisterChange}
                  placeholder="Nombre"
                />
                <input
                  type="email"
                  name="email"
                  value={registerData.email}
                  onChange={handleRegisterChange}
                  placeholder="Email"
                />
                <input
                  type="text"
                  name="address"
                  value={registerData.address}
                  onChange={handleRegisterChange}
                  placeholder="Dirección completa"
                />
                <input
                  type="text"
                  name="phone"
                  value={registerData.phone}
                  onChange={handleRegisterChange}
                  placeholder="Celular"
                />
                <input
                  type="password"
                  name="password"
                  value={registerData.password}
                  onChange={handleRegisterChange}
                  placeholder="Contraseña"
                />
                <button type="submit">REGISTRARTE</button>
                <button
                  onClick={() => {
                    window.open(
                      `https://wa.me/+59891265817?text=Necesito ayuda para iniciar sesión en Bolt Market`,
                      "_blank"
                    );
                  }}
                  className="wsp mt-3 mb-5"
                >
                  ¿ NECESITAS AYUDA ? <i className="fa-brands fa-whatsapp"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
